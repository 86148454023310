<template>
  <div>
    <zv-nav-bar :title="'名师风采'" :sourceType="sourceType"></zv-nav-bar>
    <div class="teacher-page">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="teacher-list" v-for="(item, index) in teacherList" :key="index" @click="toTeacherDetail(item)">
          <div class="img-box">
            <img :src="item.logoFile" />
          </div>
          <div class="teacher-name">
            <div class="title">
              <h5>{{ item.name }}</h5>
              <span>{{ item.courseCount }}门课程</span>
            </div>
            <div class="description" v-if="item.description">
              <div v-html="item.description"></div>
            </div>
            <p v-else>暂无描述</p>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      teacherList: [], // 教师列表
      pageIndex: 0,
      isBack: '',
      sourceType: '',
    }
  },
  created() {
    this.setShare()
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'teacher'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `名师风采`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    onLoad() {
      this.$axios
        .get(`/course/front/permit/speaker?pageSize=10&pageIndex=${this.pageIndex}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.teacherList = this.teacherList.concat(res.data.data.data)
            if (this.teacherList.length === res.data.data.total) {
              this.finished = true
            } else {
              this.pageIndex = this.pageIndex + 1
            }
          } else {
            this.$Toast.fail(res.data.message)
            this.finished = true
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$Toast.fail('服务器开小差了，稍后请重试!')
          return e
        })
    },
    // 跳转至教师详情
    toTeacherDetail(item) {
      this.$router.push({
        name: 'teacherDetail',
        params: {
          id: item.ID,
        },
      })
    },
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>

<style lang="less">
.teacher-page {
  padding: 0.02rem 0.33rem 0.33rem 0.33rem;
  background: #f8f8f8;
  min-height: 100vh;
  box-sizing: border-box;
  .teacher-list {
    background: #fff;
    margin-top: 0.33rem;
    border-radius: 0.13rem;
    padding: 0.41rem 0.43rem;
    display: flex;
    .img-box {
      height: 1.47rem;
      width: 1.47rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 0.44rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .teacher-name {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .title {
        display: flex;
        align-items: center;
        height: 0.64rem;
      }
      h5 {
        margin: 0;
        color: #202020;
        font-size: 0.48rem;
      }
      span {
        border-radius: 1rem;
        border: 1px solid #3fa1fd;
        color: #3fa1fd;
        font-size: 0.32rem;
        // padding: 0.05rem 0.2rem;
        // margin: 0.1rem 0 0.3rem 0.29rem;
        padding: 0rem 0.2rem;
        margin: 0 0 0 0.3rem;
        line-height: 0.64rem;
      }
      .description,
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 0.37rem;
        color: #666;
        width: 100%;
        margin: 0;
      }
    }
  }
  .van-list__finished-text {
    font-size: 0.35rem;
    line-height: 1rem;
  }
  .van-loading__text {
    font-size: 0.35rem;
  }
}
</style>
